import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

@Injectable()
export class CompanyService {

    constructor(private settings: SettingsService, private http: HttpClient) {}

    getAll(): Observable<any>{

        return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/companies');
    }


    get(id: number): Observable<any>{
		return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/companies/' + id + '/byid');
    }
    
   update(company: any): Observable<any>{
		return this.http.put(this.settings.getAppSetting('urlAPI') + '/api/companies/' + company.id, {company : JSON.stringify(company)});
	}
}
