import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { AuthenticationService } from '../../../core/security/authentication.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {

    constructor(public userblockService: UserblockService, public authentication: AuthenticationService) {
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    getUsername(): string{
        return this.authentication.getUsername();
    }

}
