import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageCropperModule } from 'ngx-image-cropper';

import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';

import { menu } from './menu';
import { routes } from './routes';
import { CompanyComponent } from './company/company.component';
import { LoginComponent } from './login/login.component';
import { OfferComponent } from './offer/offer.component';
import { ShopperComponent } from './shopper/shopper.component';
import { CompanyFormsComponent } from './company/company-forms/company-forms.component';
import { OfferFormsComponent } from './offer/offer-forms/offer-forms.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoriesFormsComponent } from './categories/categories-forms/categories-forms.component';



@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        NgxDatatableModule,
        FileUploadModule,
        ImageCropperModule
    ],
    declarations: [CompanyComponent, LoginComponent, OfferComponent, ShopperComponent, CompanyFormsComponent, OfferFormsComponent, CategoriesComponent, CategoriesFormsComponent],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        menuService.addMenu(menu);
    }
}
