
const headingMain = {
    text: 'Menu',
    heading: true
};

const Company = {
    text: 'Commerçants',
    link: '/commercants',
    icon: 'icon-location-pin'
};

const Offer = {
    text: 'Offres',
    link: '/offres',
    icon: 'icon-tag'
};

const Shopper = {
    text: 'Utilisateurs',
    link: '/utilisateurs',
    icon: 'icon-people'
};

const Categories = {
    text: 'Categories',
    link: '/categories',
    icon: 'icon-menu'
}



export const menu = [
    headingMain,
    Company,
    Offer,
    Shopper,
    Categories
];
