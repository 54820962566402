import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

@Injectable()
export class ShopperService {

  	constructor(private settings: SettingsService, private http: HttpClient) {}

	getAll(): Observable<any>{

	    return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/shoppers');
	}
}
