import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'; 

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let username = localStorage.getItem('username_api');
        let token = localStorage.getItem('token_api');
        if (token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(
          catchError(
            (err, caught) => {
              if (err.status === 401){
                localStorage.removeItem('username_api');
                localStorage.removeItem('token_api');
                this.router.navigateByUrl('connexion');
              }
              return throwError(err);
            }
          )
        );
    }
}