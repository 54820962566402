import { LayoutComponent } from '../layout/layout.component';
import { CompanyComponent } from './company/company.component';
import { OfferComponent } from './offer/offer.component';
import { ShopperComponent } from './shopper/shopper.component';
import { LoginComponent } from './login/login.component';
import { CategoriesComponent } from './categories/categories.component' ;

import { AuthGuard } from '../core/security/auth-guard.service';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'commercants', pathMatch: 'full' },
            { path: 'commercants', component: CompanyComponent },
            { path: 'offres', component: OfferComponent },
            { path: 'utilisateurs', component: ShopperComponent } ,
            { path: 'categories', component: CategoriesComponent }
        ]
    },

    // Not lazy-loaded routes
    { path: 'connexion', component: LoginComponent },
    // Not found
    { path: '**', redirectTo: 'commercants' }

];
