import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ShopperService } from '../../core/orm/shopper.service';

@Component({
  selector: 'app-shopper',
  templateUrl: './shopper.component.html',
  styleUrls: ['./shopper.component.scss']
})
export class ShopperComponent implements OnInit {

	@ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  	loadingIndicator: boolean = false;
	reorderable: boolean = true;
	shoppers: Array<Object> = [];
	columns: Array<Object> = [];

	constructor(private shopperService: ShopperService) {
	}

	ngOnInit() {
		this.columns = [
			{ prop: 'email', name : 'E-mail' },
			{ prop: 'city', name: 'Ville' },
			{ prop: 'created', name: 'Inscription le', cellTemplate: this.dateTemplate }
		];

		this.shopperService.getAll().subscribe((res)=>{
            console.log(res);
            this.shoppers = res;
        });
	}

}
