import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../core/settings/settings.service';
import { AuthenticationService } from '../../core/security/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';


const swal = require('sweetalert');


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    isLoading: boolean;
    redirectUrl: string;

    constructor(public settings: SettingsService, fb: FormBuilder, public authentication: AuthenticationService, public router: Router, public activatedRoute: ActivatedRoute) {

        this.loginForm = fb.group({
            'login': [null, Validators.required],
            'password': [null, Validators.required]
        });
        this.loginForm.controls['login'].setValue(this.authentication.getUsername());
        this.isLoading = false;
        this.redirectUrl = activatedRoute.snapshot.queryParams["redirectUrl"];
        

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.loginForm.controls) {
            this.loginForm.controls[c].markAsTouched();
        }
        // Login form is valid
        if (this.loginForm.valid) {
            this.isLoading = true;
            this.authentication.login(value.login.trim(), value.password.trim()).subscribe(
                response => {
                    this.isLoading = false;
                    if(this.redirectUrl === undefined)
                        this.router.navigateByUrl('/');
                    else
                        this.router.navigateByUrl(this.redirectUrl);
                },
                response => {
                  swal('Votre authentification a échouée ('+ response.status +')', 'Veuillez vérifier votre nom d\'utilisateur et votre mot de passe puis, réessayer. Si le problème perssiste, contactez nous.' , 'warning');
                  this.isLoading = false;
            });
        }
    }

    ngOnInit() {

    }

}
