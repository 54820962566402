import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, AfterViewInit, AfterViewChecked} from '@angular/core';
import { OfferService } from '../../core/orm/offer.service';
import { OfferFormsComponent } from './offer-forms/offer-forms.component';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  encapsulation: ViewEncapsulation.None ,
})
export class OfferComponent implements OnInit {

	@ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
	@ViewChild('offerForm') offerForm: OfferFormsComponent ;
  
	public offers: Array<any> = [];
	public selectedOffer: any = null;
	public columns: Array<any> = [];
	public loading: boolean = false;

	constructor(private offerService: OfferService) {
	}

	ngOnInit() {
		this.columns = [
			{ prop: 'company.name', name : 'Entreprise' },
			{ prop: 'title', name : 'Titre' },
			{ prop: 'descriptionMin', name: 'Description' },
			{ prop: 'created', name: 'Créée le', cellTemplate: this.dateTemplate },
			{ prop: 'updated', name: 'Mise à jour le', cellTemplate: this.dateTemplate }
		];

		this.loading = true;
		this.offerService.getAll().subscribe(
			(res)=>{
	            this.offers = res;
				this.loading = false;
			},
			(error)=>{
				console.error(error);
				this.loading = false;
			}
		);
	}

	public onActivate(event) {
    	if(event.type == 'click') {
			this.selectedOffer = event.row;
			this.offerForm.openModal(this.selectedOffer) ;
    	}
	}
}
