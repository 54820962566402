import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, AfterViewInit, AfterViewChecked} from '@angular/core';
import { CategoryService } from '../../core/orm/category.service';
import { CategoriesFormsComponent } from './categories-forms/categories-forms.component';
import * as _ from 'underscore';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'], 
   encapsulation: ViewEncapsulation.None,
})

export class CategoriesComponent implements OnInit {
	@ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
	@ViewChild('categoriesForm') categoriesForm: CategoriesFormsComponent ;

	public selectedCategory: any = null;
	public categories: Array<Object> = [];
	public columns: Array<Object> = [];

	constructor(private categoryService: CategoryService) { }

	ngOnInit() {
		this.columns = [
			{ prop: 'name', name : 'Categorie' },
			{ prop: 'description', name: 'Description' },
		];

		this.categoryService.getAll().subscribe((res)=>{
            console.log(res);
			this.categories = res;
		});
	}

	public button(){
		this.categoriesForm.newModal();
	}

	public onActivate(event) {
    	if(event.type == 'click') {
			this.selectedCategory = event.row ;
			this.categoriesForm.openModal(this.selectedCategory) ;
		}		
	}
}
