import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private settings: SettingsService, private http: HttpClient) { }


	getAll(): Observable<any>{
		return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/categories') ;
	}

    get(id: number): Observable<any>{
		return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/categories/' + id );
    }

	update(category: any): Observable<any>{
		return this.http.put(this.settings.getAppSetting('urlAPI') + '/api/categories/' + category.id, {category: JSON.stringify(category)});
	}

	create(category: any): Observable<any>{
		return this.http.post(this.settings.getAppSetting('urlAPI') + '/api/categories', {category: JSON.stringify(category)});
	}


}
