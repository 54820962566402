import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

const TOKEN_KEY = 'token_api';
const USERNAME_KEY = 'username_api';

@Injectable()
export class AuthenticationService {

    constructor(private settings: SettingsService, private http: HttpClient) {
    }

    login(login: string, password: string): Observable<any>{

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            
          })
        };
        return this.http.post(this.settings.getAppSetting('urlAPI')+'/api/login_check', { _username: login, _password: password}, httpOptions)
        .pipe(
          retry(2),
          tap(data => {
              this.setToken(data.token);
              this.setUsername(login);
          })
        );
    }

    logout(): boolean {
      localStorage.removeItem(TOKEN_KEY);
      return true;
    }

    setToken(token:string): void {
        localStorage.setItem(TOKEN_KEY, token);
    }

    setUsername(username: string){
      localStorage.setItem(USERNAME_KEY, username);
    }

    getUsername(): string{
      return localStorage.getItem(USERNAME_KEY);
    }

    isLogged(): boolean{
      return localStorage.getItem(TOKEN_KEY) != null;
    }

    getToken(): string{
      return localStorage.getItem(TOKEN_KEY);
    }

}
