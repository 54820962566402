import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

@Injectable()
export class OfferService {

	constructor(private settings: SettingsService, private http: HttpClient) {}

	getAll(): Observable<any>{

	    return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/all/offers');
	}

	get(id: number): Observable<any>{
		return this.http.get(this.settings.getAppSetting('urlAPI') + '/api/offers/' + id);
	}

	update(offer: any): Observable<any>{
		return this.http.put(this.settings.getAppSetting('urlAPI') + '/api/offers/' + offer.id, {offer : JSON.stringify(offer)});
	}

}
