import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, AfterViewInit, AfterViewChecked} from '@angular/core';
import { CompanyService } from '../../core/orm/company.service';
import { CompanyFormsComponent } from './company-forms/company-forms.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'] ,
  encapsulation: ViewEncapsulation.None,
})

export class CompanyComponent implements OnInit{
	@ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
	@ViewChild('companyForm') companyForm: CompanyFormsComponent ;

	public selectedCompany: any = null;
	public companies: Array<Object> = [];
	public columns: Array<Object> = [];
	public loading: boolean = false;

	constructor(private companyService: CompanyService) {   
	}

	ngOnInit() {
		this.columns = [
			{ prop: 'name', name : 'Entreprise' },
			{ prop: 'description', name: 'Description' },
			{ prop: 'created', name: 'Inscrit le', cellTemplate: this.dateTemplate },
		];

		this.loading = true;
		this.companyService.getAll().subscribe(
			(res)=>{
	            this.companies = res;
				this.loading = false;
			},
			(error)=>{
				console.error(error);
				this.loading = false;
			}
		);
	}

	public onActivate(event) {
    	if(event.type == 'click') {
			this.selectedCompany = event.row ;
			this.companyForm.openModal(this.selectedCompany) ;
		}		
	}
}
