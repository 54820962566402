import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { TranslatorService } from './translator/translator.service';
import { MenuService } from './menu/menu.service';

/* Security */
import { AuthenticationService } from './security/authentication.service';
import { AuthGuard } from './security/auth-guard.service';
import { JwtInterceptor } from './security/jwt.interceptor';


/* ORM */
import { CompanyService } from './orm/company.service';
import { OfferService } from './orm/offer.service';
import { ShopperService } from './orm/shopper.service';

import { throwIfAlreadyLoaded } from './module-import-guard';

@NgModule({
    imports: [
    ],
    providers: [
        SettingsService,
        ThemesService,
        TranslatorService,
        MenuService,
        AuthenticationService,
        AuthGuard,
        CompanyService,
        OfferService,
        ShopperService,
        JwtInterceptor
    ],
    declarations: [],
    exports: [
    ]
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
