import { Component, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'; 
import { CategoryService } from '../../../core/orm/category.service';

@Component({
  selector: 'app-categories-forms',
  templateUrl: './categories-forms.component.html',
  styleUrls: ['./categories-forms.component.scss']
})
export class CategoriesFormsComponent{
  category: Object;

  public selectedCategory: any = null;
  public newCategory: any = {name: null, description: null };
  public loadingIndicator: boolean = false;
	public reorderable: boolean = true;
	public modalRef: BsModalRef;
	public modalLoading: boolean = false;

    @ViewChild('modalTemplate') template: any;
    @ViewChild('newTemplate') newTemplate: any;


  constructor(private modalService: BsModalService, private categoryService: CategoryService) { }

  newModal(){
    this.modalRef = this.modalService.show(this.newTemplate, Object.assign({}, { class: "modal-lg"}));
    this.modalLoading = true; 
  }

  openModal(selectedCategory){
    this.selectedCategory = selectedCategory;
    this.modalRef = this.modalService.show(this.template, Object.assign({}, { class: 'modal-lg' }));
    this.modalLoading = true;
    this.categoryService.get(this.selectedCategory.id).subscribe(
      (res)=>{
        this.selectedCategory = res;

        this.modalLoading = false;
        console.log(res);
      },(error) =>{
        this.modalLoading = false;
        console.log(error);
      }
    );
  }

  public onSubmitPost(){
    this.modalLoading = true;
    this.categoryService.create(this.newCategory).subscribe(
      (res)=>{
        this.newCategory.update = res.updated;
        this.modalLoading = false;
        this.modalRef.hide();
        console.log(this.newCategory);
      },(error)=>{
        this.modalLoading = false;
        console.log(error);
      }
    );
  }

  public onSubmitUpdate(){
    this.modalLoading = true;
    this.categoryService.update(this.selectedCategory).subscribe(
      (res)=>{
        this.selectedCategory.updated = res.updated;
        this.modalLoading = false;
        this.modalRef.hide();
      },(error)=>{
        this.modalLoading = false;
        console.log(error);
      }
    );
  }
}
